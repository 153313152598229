import { isPlatform } from "@ionic/core";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { InAppBrowser } from "@ionic-native/in-app-browser";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { logEvent } from "@src/appV2/lib/analytics";
import { USER_EVENTS } from "@src/constants";
import { SUPPORT_LINKS as DEFAULT_SUPPORT_LINKS } from "@src/constants/DEFAULT_SUPPORT_LINKS";
import { TERMS_OF_SERVICE_URL } from "@src/constants/urls";
import { ZendeskSDK } from "capacitor-zendesk-sdk";
import { book, chatboxEllipses, helpOutline } from "ionicons/icons";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./style.scss";

import { useSession } from "../store/helperHooks";
import { setSupportUnreadMessageBoolean } from "../store/Notifications";
import { Store } from "../store/store.model";

declare global {
  interface Window {
    adaEmbed: any;
  }
}

const openBrowser = (url: string, target = "_system") => {
  InAppBrowser.create(url, target);
};

export function NeedHelpPage() {
  const isCapacitor = isPlatform(window, "capacitor");
  const { agent } = useSession();
  // We both want a default value for the feature flag and for each individual key
  const supportLinks = {
    ...DEFAULT_SUPPORT_LINKS,
    ...useCbhFlag(CbhFeatureFlag.SUPPORT_LINKS, { defaultValue: DEFAULT_SUPPORT_LINKS }),
  };

  const { hasUnreadSupportMessage } = useSelector((state: Store) => state.notificationStore);
  const dispatch = useDispatch();

  if (!agent) {
    return <IonSpinner color="light" />;
  }

  const openHelpCenter = () => {
    logEvent(USER_EVENTS.OPENED_HELP_CENTER);
    window.open(supportLinks.HELP_CENTER_WORKER);
  };

  const openZendesk = async () => {
    logEvent(USER_EVENTS.STARTED_CHAT);
    dispatch(setSupportUnreadMessageBoolean(false));
    if (isCapacitor) {
      await ZendeskSDK.showMessaging();
    } else {
      window.zE("messenger", "open");
      window.zE("messenger", "show");
      window.zE("messenger:on", "close", () => {
        window.zE("messenger", "hide");
      });
    }
  };

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home/account" mode="ios" />
          </IonButtons>
          <IonTitle style={{ marginLeft: "8px" }}>Help Center</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem className="help-item" detail onClick={() => openBrowser(TERMS_OF_SERVICE_URL)}>
            <IonIcon className="listIcons" icon={book} />
            <IonLabel>
              <p>The contractor role & how we work together</p>
              <h2>Terms of Service</h2>
            </IonLabel>
          </IonItem>
          <Fragment>
            <IonItem className="help-item" onClick={openZendesk} detail>
              <IonIcon className="listIcons" icon={chatboxEllipses} />
              <IonLabel>
                <p>Contact us using chat</p>
                <h2 className={hasUnreadSupportMessage ? "notification-dot" : ""}>Start a Chat</h2>
              </IonLabel>
            </IonItem>

            <IonItem
              className="help-item"
              onClick={openHelpCenter}
              data-testid="open-help-center"
              detail
            >
              <IonIcon className="listIcons" icon={helpOutline} />
              <IonLabel>
                <p>Read help articles and contact us</p>
                <h2>Help Center</h2>
              </IonLabel>
            </IonItem>
          </Fragment>
        </IonList>
      </IonContent>
    </IonPage>
  );
}
