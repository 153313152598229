import { firebaseConfig } from "@src/appV2/Auth/firebase";
import { environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics";
import { Agent, PaymentAccountInfo } from "@src/lib/interface";
import request from "superagent";

import { getAuthHeader } from "../superagent";

const generateDynamicLink = async (link: string): Promise<string> => {
  try {
    const { body } = await request
      .post(`${firebaseConfig.dynamicShortLinkApiUrl}`)
      .query({ key: firebaseConfig.apiKey })
      .send({
        dynamicLinkInfo: {
          domainUriPrefix: environmentConfig.REACT_APP_FIREBASE_DYNAMIC_LINK_DOMAIN,
          link,
          androidInfo: {
            androidPackageName: environmentConfig.REACT_APP_FIREBASE_BUNDLE_ID,
          },
          iosInfo: {
            iosBundleId: environmentConfig.REACT_APP_FIREBASE_BUNDLE_ID,
          },
        },
      });
    return body.shortLink;
  } catch (error) {
    console.log(error);
    logApiFailureEvent(error);
    throw new Error(error);
  }
};

const signedContractorAgreement = async (
  agentId: string,
  agreementVersion,
  signature: string | null = null
): Promise<Agent> => {
  try {
    const { body } = await request
      .patch(
        `${environmentConfig.REACT_APP_WORKER_SERVICE_URL}/agentprofile/signContractorAgreement`
      )
      .set(await getAuthHeader())
      .send({ agentId, agreementVersion, signature });
    return body;
  } catch (error) {
    logApiFailureEvent(error);
    throw new Error(error);
  }
};

const generatePaymentServiceOnboardingLink = async (
  id: string,
  data: { refreshUrl: string; returnUrl: string }
): Promise<string> => {
  try {
    const paymentServiceUser = await request
      .post(`${environmentConfig.REACT_APP_PAYMENTS_API_URL}/accounts/${id}/generate-account-link`)
      .set(await getAuthHeader())
      .send(data)
      .then(({ text }) => text);
    return paymentServiceUser;
  } catch (error) {
    console.log(error);
    logApiFailureEvent(error);
    throw new Error(error);
  }
};

const generateExpressSignInLinkToStripe = async (
  id: string,
  data: { returnUrl: string }
): Promise<string> => {
  try {
    const paymentServiceUser = await request
      .post(
        `${environmentConfig.REACT_APP_PAYMENTS_API_URL}/accounts/${id}/generate-express-account-link`
      )
      .set(await getAuthHeader())
      .send(data)
      .then(({ text }) => text);
    return paymentServiceUser;
  } catch (error) {
    console.log(error);
    logApiFailureEvent(error);
    throw new Error(error);
  }
};

interface CreatePaymentServiceAccount {
  agentId: string;
  email: string;
  msa: string;
  individual: {
    firstName?: string;
    lastName?: string;
    phone?: string;
    birthday?: string;
    address?: {
      line1?: string;
      postalCode?: string;
      city?: string;
      state?: string;
    };
  };
}
const createPaymentServiceAccount = async (
  data: Partial<CreatePaymentServiceAccount>
): Promise<PaymentAccountInfo> => {
  try {
    const paymentServiceAccount = await request
      .post(`${environmentConfig.REACT_APP_PAYMENTS_API_URL}/accounts`)
      .set(await getAuthHeader())
      .send(data)
      .then(({ body }) => body);
    return paymentServiceAccount;
  } catch (error) {
    console.log(error);
    logApiFailureEvent(error);
    throw error;
  }
};

export {
  generatePaymentServiceOnboardingLink,
  generateExpressSignInLinkToStripe,
  createPaymentServiceAccount,
  generateDynamicLink,
  signedContractorAgreement,
};
