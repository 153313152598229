import { Agent } from "@src/lib/interface";

import { ActionType as sessionActionType } from "../store/session";

export const updateAgentProfileState = (data: Partial<Agent>) => ({
  type: sessionActionType.UPDATE_AGENT,
  data: {
    agent: data,
  },
});
